import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { PublicPart } from 'ngx-shared';

import {
    MonitorOverview, OverviewStatuses, SectionMonitorInfo, SectionMonitorStatus, SectionTrendData, Issue,
    RuleInstanceLastCheckedData, MatchingIssuesFilter, MatchingIssuesResult, RuleSuppressionInfo,
    IssueDetails,
} from '@/_models';

class SectionMonitorInstanceDataBody {
    sectionId: number;
    categoryService?: string;
    categoryType?: string;
    categorySubject?: string;
    subjectType?: string;
    subjectId?: string;
    monitorId?: string;
    targetId?: number;
}

class SectionMonitorTrendDataBody {
    sectionId: number;
    nodeIds: string[];
    earliestUtcTime: Date;
    latestUtcTime: Date;
    maximumNumberOfPoints?: number;
    targetIds: number[];
}

@Injectable({ providedIn: 'root' })
export class MonitorService {

    constructor(private http: HttpClient) { }

    getOverviewOrderSections(includeCompleted: boolean): Observable<MonitorOverview[]> {
        return this.http.get<MonitorOverview[]>(`${environment.portalWebApi}/Monitor/overview?includeCompleted=${includeCompleted}`).pipe(
            // parse strings to date objects
            tap(x => x && x.filter(item => item.startDate).forEach(item => {
                if (item.startDate) { item.startDate = new Date(item.startDate); }
                if (item.rigStateTimeIndex) { item.rigStateTimeIndex = new Date(item.rigStateTimeIndex); }
                if (item.holeDepthTimeIndex) { item.holeDepthTimeIndex = new Date(item.holeDepthTimeIndex); }
                if (item.bitDepthTimeIndex) { item.bitDepthTimeIndex = new Date(item.bitDepthTimeIndex); }
                if (item.rigLocalTime) { item.rigLocalTime = new Date(item.rigLocalTime); }
            }))
        );
    }

    getOverviewStatuses(sectionId: number): Observable<OverviewStatuses> {
        return this.http.get<OverviewStatuses>(`${environment.statusAggregatorApi}/Status/section/${sectionId}`, {
            headers: {
                SectionId: sectionId.toString()
            }
        });
    }

    getSectionMonitorInfo(sectionId: number): Observable<SectionMonitorInfo> {
        return this.http.get<SectionMonitorInfo>(`${environment.portalWebApi}/Monitor/section/${sectionId}`).pipe(
            // parse strings to date objects
            tap(x => {
                if (x) {
                    if (x.holeDepthTimeIndex) { x.holeDepthTimeIndex = new Date(x.holeDepthTimeIndex); }
                    if (x.bitDepthTimeIndex) { x.bitDepthTimeIndex = new Date(x.bitDepthTimeIndex); }
                    if (x.rigStateTimeIndex) { x.rigStateTimeIndex = new Date(x.rigStateTimeIndex); }
                    if (x.rigLocalTime) { x.rigLocalTime = new Date(x.rigLocalTime); }
                    if (x.sectionStart) { x.sectionStart = new Date(x.sectionStart); }
                    if (x.sectionEnd) { x.sectionEnd = new Date(x.sectionEnd); }
                    if (x.activatedDate) { x.activatedDate = new Date(x.activatedDate); }
                }
            })
        );
    }

    getSectionMonitorStatuses(sectionId: number): Observable<SectionMonitorStatus> {
        return this.http.get<SectionMonitorStatus>(`${environment.statusAggregatorApi}/Status/tree/${sectionId}`, {
            headers: {
                SectionId: sectionId.toString()
            }
        });
    }

    getSectionMonitorTrendData(
        sectionId: number,
        earliestTime: Date | null,
        latestTime: Date | null,
        categoryIds: string[],
        targetId: number
    ): Observable<SectionTrendData> {
        const body: SectionMonitorTrendDataBody = {
            sectionId,
            nodeIds: categoryIds,
            earliestUtcTime: earliestTime,
            latestUtcTime: latestTime,
            targetIds: [targetId],
            maximumNumberOfPoints: 100,
        };
        return this.http.post<SectionTrendData>(`${environment.statusAggregatorApi}/Status/trend`, body, {
            headers: {
                SectionId: sectionId.toString()
            }
        }).pipe(
            // parse strings to date objects
            tap(x => {
                if (x && Array.isArray(x.lines)) {
                    x.lines.forEach(line => {
                        if (line && Array.isArray(line.points)) {
                            line.points.forEach(item => {
                                if (item) {
                                    item.dateTimeUtc = item && new Date(item.dateTimeUtc);
                                }
                            });
                        }
                    });
                }
            })
        );
    }

    getRuleInstanceLastCheckedData(sectionId: number, targetId: number,
        categoryService?: string, categoryType?: string, categorySubject?: string,
        subjectType?: string, subjectId?: string, monitorId?: string
    ): Observable<RuleInstanceLastCheckedData[]> {
        const body: SectionMonitorInstanceDataBody = {
            sectionId,
            categoryService: categoryService,
            categoryType: categoryType,
            categorySubject: categorySubject,
            subjectId: subjectId,
            subjectType: subjectType,
            monitorId: monitorId,
            targetId: targetId,
        };
        return this.http.post<RuleInstanceLastCheckedData[]>(`${environment.ruleEngineApi}/Rule/lastdata`, body, {
            headers: {
                SectionId: sectionId.toString()
            }
        }).pipe(
            tap(x => {
                x.forEach(element => {
                    if (element.time) { element.time = new Date(element.time); }
                    if (element.issueStartRigTime) { element.issueStartRigTime = new Date(element.issueStartRigTime); }
                    if (element.issueEndRigTime) { element.issueEndRigTime = new Date(element.issueEndRigTime); }
                    if (element.suppressedUntilRigTime) { element.suppressedUntilRigTime = new Date(element.suppressedUntilRigTime); }
                });
            })
        );
    }

    getSectionMonitorIssues(ruleInstanceIds: number[]): Observable<Issue[]> {
        return this.http.post<Issue[]>(`${environment.portalWebApi}/Monitor/issues`, ruleInstanceIds).pipe(
            tap(x => { x.forEach(i => i.issueDetailsFetched = false); })
        );
    }

    getIssueDetails(issueIds: number[]): Observable<IssueDetails[]> {
        return this.http.post<IssueDetails[]>(`${environment.portalWebApi}/Monitor/issues/details`, issueIds);
    }

    updateRuleInstanceState(sectionId: number, ruleInstanceIds: number[], isEnabled: boolean): Observable<void> {
        const body = Array.isArray(ruleInstanceIds) ? ruleInstanceIds.map(x => ({ ruleInstanceId: x, isEnabled: isEnabled })) : null;
        return this.http.post<void>(`${environment.portalWebApi}/Monitor/section/${sectionId}/ruleinstancestate`, body);
    }

    updateRuleInstanceAlertState(sectionId: number, ruleInstanceIds: number[], isEnabled: boolean): Observable<void> {
        const body = Array.isArray(ruleInstanceIds) ? ruleInstanceIds.map(x => ({ ruleInstanceId: x, isEnabled: isEnabled })) : null;
        return this.http.post<void>(`${environment.portalWebApi}/Monitor/section/${sectionId}/ruleinstancealertstate`, body);
    }

    getMyRigs(): Observable<number[]> {
        return this.http.get<number[]>(`${environment.portalWebApi}/Monitor/myRigs`);
    }

    getMatchingIssues(filter: MatchingIssuesFilter): Observable<MatchingIssuesResult[]> {
        return this.http.post<MatchingIssuesResult[]>(`${environment.portalWebApi}/Monitor/matchingIssues`, filter);
    }

    setRuleSuppression(ruleInstanceId: number, info: RuleSuppressionInfo, sectionId: number): Observable<void> {
        return this.http.post<void>(`${environment.ruleEngineApi}/Rule/${ruleInstanceId}/suppression`, info, {
            headers: {
                SectionId: sectionId.toString()
            }
        });
    }

    setProblemTimeSuppression(ruleInstanceId: number, info: RuleSuppressionInfo, sectionId: number): Observable<void> {
        return this.http.post<void>(`${environment.ruleEngineApi}/Rule/${ruleInstanceId}/problemtime/suppression`, info, {
            headers: {
                SectionId: sectionId.toString()
            }
        });
    }
}

/* eslint-disable */
export class FakeMonitorService implements PublicPart<MonitorService> {
    getOverviewOrderSections(includeCompleted: boolean): Observable<MonitorOverview[]> {
        throw new Error('Method not implemented.');
    }
    getOverviewStatuses(sectionId: number): Observable<OverviewStatuses> {
        throw new Error('Method not implemented.');
    }
    getSectionMonitorInfo(sectionId: number): Observable<SectionMonitorInfo> {
        throw new Error('Method not implemented.');
    }
    getSectionMonitorStatuses(sectionId: number): Observable<SectionMonitorStatus> {
        throw new Error('Method not implemented.');
    }

    getSectionMonitorTrendData(
        sectionId: number,
        earliestTime: Date | null,
        latestTime: Date | null,
        categoryIds: string[],
        targetId: number
    ): Observable<SectionTrendData> {
        throw new Error('Method not implemented.');
    }

    getRuleInstanceLastCheckedData(sectionId: number, targetId: number): Observable<RuleInstanceLastCheckedData[]> {
        throw new Error('Method not implemented.');
    }

    getSectionMonitorIssues(ruleInstanceIds: number[]): Observable<Issue[]> {
        throw new Error('Method not implemented.');
    }

    getIssueDetails(issueIds: number[]): Observable<IssueDetails[]> {
        throw new Error('Method not implemented.');
    }

    updateRuleInstanceState(sectionId: number, ruleInstanceIds: number[], isEnabled: boolean): Observable<void> {
        throw new Error('Method not implemented.');
    }

    updateRuleInstanceAlertState(sectionId: number, ruleInstanceIds: number[], isEnabled: boolean): Observable<void> {
        throw new Error('Method not implemented.');
    }

    getMyRigs(): Observable<number[]> {
        throw new Error('Method not implemented.');
    }

    getMatchingIssues(filter: MatchingIssuesFilter): Observable<MatchingIssuesResult[]> {
        throw new Error('Method not implemented.');
    }

    setRuleSuppression(ruleInstanceId: number, info: RuleSuppressionInfo, sectionId: number): Observable<void> {
        throw new Error('Method not implemented.');
    }

    setProblemTimeSuppression(ruleInstanceId: number, info: RuleSuppressionInfo, sectionId: number): Observable<void> {
        throw new Error('Method not implemented.');
    }
}

export const fakeMonitorServiceProvider = { provide: MonitorService, useClass: FakeMonitorService };
/* eslint-enable */
