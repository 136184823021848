import { IdNamePair } from '../id-name-pair';
import { RuleCategoryType } from './rule-category-type';

export const KpiOverrideNoStartDate = 'Section Activation';
export const KpiOverrideNoEndDate = 'Section Completion';

export class KpiManagementCatalogMnemonic {
    public name: string;
    public serviceId: number;
    public serviceProviderId: number;
    public tool: string;
    public curveIndexTypeId: number;
    public mnemonic: string;
    public logName: string;
}

export class KpiOverrideTemplateSubject {
    public id: number;
    public name: string;
    public serviceId: number;
    public serviceProviderId: number;
    public tool: string;
    public curveIndexTypeId: number;
    public mnemonic: string;
    public logName: string;
}

export class KpiOverrideBase {
    public id: number;
    public name: string;
    public isGreen: boolean;
    public comment?: string;
    public subjectType: string;
    public includeCurves: boolean;
    public type: RuleCategoryType;
    public service: string;
    public rules: number[];
    public problemTimeRelation: string;
    public problemTimeDuration: number;
    public templateGroupId?: number;
}

export class KpiOverride extends KpiOverrideBase {
    public sectionId: number;
    public witsmlServerConnectionId: number;

    public startsWithSection = true;
    public startDateTimeUtc?: Date;
    public endsWithSection = true;
    public endDateTimeUtc?: Date;
    public subjects: number[];
    public templateName: string;

    // todo: deprecated; leave them for consistency and no build errors
    /* @deprecated */
    public isConstant: boolean;
    /* @deprecated */
    public weight: number;
    /* @deprecated */
    public ruleId: number;
    /* @deprecated */
    public indexType: RuleCategoryType;
}

export class KpiOverrideTemplate extends KpiOverrideBase {
    public subjects: KpiOverrideTemplateSubject[];
}

export class KpiManagementFilterLists {
    public rule: IdNamePair[];
    public subjectType: IdNamePair[];
    public serviceProvider: IdNamePair[];
    public overrideTemplate: IdNamePair[];
    public indexType: IdNamePair[];
    public wellKnownServiceName: IdNamePair[];
    public mnemonicCatalog: KpiManagementCatalogMnemonic[];
}

export class CopyKpiOverrideTemplateStatus {
    public id: number;
    public name: string;
    public applied: string;
}