import { IndicatorStatus } from "../indicator-status";
import { SectionMonitorRuleInstance } from "./section-monitor-rule-instance";

export class RuleInstanceLastCheckedData extends SectionMonitorRuleInstance {
    public subjectMappedName: string;
    public logName: string;
    public curveLabel: string;
    public service: string;
    public type: string;
    public subject: string;
    
    public enabled?: boolean;
    public isRuleActive?: boolean;
    public status?: IndicatorStatus;
    public issueCount?: number;

    // last checked data
    public time?: Date;
    public value?: string;
    public expectation?: string;
    public alertShouldCreate?: boolean;

    // last issue data
    public issueStartRigTime?: Date;
    public issueEndRigTime?: Date;
}
