import { IndicatorStatus } from './indicator-status';
import { ProblemTimeData } from './problem-time-data';
import { StateDetails } from './state-details/state-details';

export class Issue extends ProblemTimeData {
    public id: number;
    public ruleInstanceId: number;
    public alertId: number;
    public status: IndicatorStatus;

    public startTime: Date;
    public startTimeTicks: string;
    /** End time of the issue. Opened issues do not have end time */
    public endTime?: Date;
    public startTimeIndex?: Date;
    public endTimeIndex?: Date;
    public startDepthIndex?: number;
    public startDepthIndexUom?: string;
    public endDepthIndex?: number;
    public endDepthIndexUom?: string;

    public reasonCode: number;
    public reasonCodeDescription: string;
    /** Created by the Rule */
    public comment?: string;
    public closeReason?: string;
    public taggedCurvesInfo?: string;
    public stateDetails?: StateDetails;
    public ruleParametersInfo?: string;
    public issueDetailsFetched = false;
    /** Is the issue is marked as problem time */
    public problemTime: boolean;
}

export class IssueDetails {
    public id: number;
    public stateDetails?: StateDetails;
    public taggedCurvesInfo?: string;
    public ruleParametersInfo?: string;
}
