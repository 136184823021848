import { IssueReasonCode } from '../reason-code';
import { RuleSuppressionStatus } from '../rule-management';

export class SectionMonitorRuleInstance {
    public ruleInstanceId: number;
    public subjectType: string;
    public subjectName: string;
    public monitor: string;

    public indexType: string;
    public uid: string;
    public logUid: string;

    public suppressionStatus: RuleSuppressionStatus;
    public suppressedUntilRigTime?: Date;
    public suppressedReasonCode?: IssueReasonCode;
    public suppressedReasonCodeDescription?: string;

    public problemTimeSuppressionStatus: RuleSuppressionStatus;
    public problemTimeSuppressedReasonCode?: IssueReasonCode;
    public problemTimeSuppressedReasonCodeDescription?: string;
}
