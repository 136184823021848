import { Store, Action } from '../ngrx-actions';

import { KpiState } from './kpi.state';
import {
    LoadInStateSectionListAction, AddInStateOverrideAction, LoadInStateOverridesAction, UpdateInStateOverrideAction,
    DeleteInStateOverrideAction, UpdateInStateIssuesProblemTimeFlagAction, LoadInStateIssueReasonCodesAction,
    LoadInStateKpiChartDataAction, InsertInStateKpiIssueBatchAction, FinishFetchKpiIssueBatchAction, StartFetchKpiIssueBatchAction,
    LoadInStateSectionWithRuleInstancesAction, UpdateInStateSectionTimeRangeAction, ClearAllIssuesAction,
    UpdateInStateAllIssuesProblemTimeFlagAction, InsertInStateSectionTimeRangeAction, DeleteInStateSectionTimeRangeAction,
    LoadInStateKpiExportSummaryAction, UpdateInStateKpiExportFlagAction, LoadInStateSectionExportListAction,
    UpdateKpiExportStatusAction, UpdateInStateKpiFinalizeAction,
    LoadInStateDapKpiExportSummaryAction,
    LoadInStateLiveTimeRangesAction,
    MarkKpiExportAsTracked,
    CancelCurrentKpiExportTracking,
    ClearKpiExportStatusAction,
    LoadInStateOverrideTemplatesAction, AddInStateOverrideTemplateAction,
    UpdateInStateOverrideTemplateAction,
    DeleteInStateOverrideTemplateAction,
    LoadInStateKpiFilterListsAction,
    ApplyInStateOverrideTemplateAction
} from './kpi.actions';
import { KPIExportDetails, KPIExportStatus, KPIExportTracking } from '@/_models';

@Store({} as KpiStore)
export class KpiStore {
    @Action(LoadInStateSectionListAction)
    public loadInStateSectionList(state: KpiState, { sectionList }: LoadInStateSectionListAction) {
        return { ...state, sectionList: sectionList } as KpiState;
    }
    @Action(LoadInStateSectionExportListAction)
    public loadInStateSectionExportList(state: KpiState, { sectionList }: LoadInStateSectionExportListAction) {
        return { ...state, sectionExportList: sectionList } as KpiState;
    }

    //#region overrides
    @Action(LoadInStateOverridesAction)
    public loadInStateOverrides(state: KpiState, { overrides }: LoadInStateOverridesAction) {
        return { ...state, overrides: overrides } as KpiState;
    }
    @Action(AddInStateOverrideAction)
    public addInStateOverride(state: KpiState, { override }: AddInStateOverrideAction) {
        if (!state.overrides) {
            throw new Error('Overrides state not defined');
        }

        if (!override) {
            throw new Error('KpiOverride not defined');
        }

        if (override.id == null) {
            throw new Error(`Override id (${override.id}) not defined`);
        }

        const newOverrides = [...state.overrides];
        newOverrides.push(override);

        return { ...state, overrides: newOverrides } as KpiState;
    }

    @Action(UpdateInStateOverrideAction)
    public updateInStateOverride(state: KpiState, { override }: UpdateInStateOverrideAction) {
        if (!state.overrides) {
            throw new Error('Overrides state not defined');
        }

        if (!override) {
            throw new Error('KpiOverride not defined');
        }

        if (override.id == null) {
            throw new Error(`Override id (${override.id}) not defined`);
        }

        const index = state.overrides.findIndex(x => x.id === override.id);

        if (index < 0) {
            throw new Error(`Override (id: ${override.id}) not found in state`);
        }

        const newOverrides = [...state.overrides];
        newOverrides[index] = override;

        return { ...state, overrides: newOverrides } as KpiState;
    }

    @Action(DeleteInStateOverrideAction)
    public deleteInStateOverride(state: KpiState, { id }: DeleteInStateOverrideAction) {
        if (!state.overrides) {
            throw new Error('Overrides state not defined');
        }

        if (id == null) {
            throw new Error(`Override id (${id}) not defined`);
        }

        const index = state.overrides.findIndex(x => x.id === id);

        if (index < 0) {
            throw new Error(`Override (id: ${id}) not found in state`);
        }

        const newOverrides = [...state.overrides];
        newOverrides.splice(index, 1);

        return { ...state, overrides: newOverrides } as KpiState;
    }
    //#endregion overrides
    
    @Action(LoadInStateKpiFilterListsAction)
    public loadInStateFilterLists(state: KpiState, { filterLists }: LoadInStateKpiFilterListsAction) {
        return { ...state, filterLists: filterLists } as KpiState;
    }

    //#region override templates
    @Action(LoadInStateOverrideTemplatesAction)
    public loadInStateOverrideTemplates(state: KpiState, { overrideTemplates }: LoadInStateOverrideTemplatesAction) {
        return { ...state, overrideTemplates: overrideTemplates } as KpiState;
    }
    @Action(AddInStateOverrideTemplateAction)
    public addInStateOverrideTemplate(state: KpiState, { overrideTemplate }: AddInStateOverrideTemplateAction) {
        if (!state.overrideTemplates) {
            throw new Error('Override templates state is not defined');
        }

        if (!overrideTemplate) {
            throw new Error('Kpi override template not defined');
        }

        if (overrideTemplate.id == null) {
            throw new Error(`Override template id (${overrideTemplate.id}) is not defined`);
        }

        const newOverrides = [...state.overrideTemplates];
        newOverrides.push(overrideTemplate);

        return { ...state, overrideTemplates: newOverrides } as KpiState;
    }

    @Action(UpdateInStateOverrideTemplateAction)
    public updateInStateOverrideTemplate(state: KpiState, { overrideTemplate }: UpdateInStateOverrideTemplateAction) {
        if (!state.overrideTemplates) {
            throw new Error('Override templates state is not defined');
        }

        if (!overrideTemplate) {
            throw new Error('Kpi override template not defined');
        }

        if (overrideTemplate.id == null) {
            throw new Error(`Override template id (${overrideTemplate.id}) is not defined`);
        }

        const index = state.overrideTemplates.findIndex(x => x.id === overrideTemplate.id);

        if (index < 0) {
            throw new Error(`Override template (id: ${overrideTemplate.id}) is not found in state`);
        }

        const newOverrides = [...state.overrideTemplates];
        newOverrides[index] = overrideTemplate;

        return { ...state, overrideTemplates: newOverrides } as KpiState;
    }

    @Action(DeleteInStateOverrideTemplateAction)
    public deleteInStateOverrideTemplate(state: KpiState, { id }: DeleteInStateOverrideTemplateAction) {
        if (!state.overrideTemplates) {
            throw new Error('Override templates state is not defined');
        }

        if (id == null) {
            throw new Error(`Override template id (${id}) is not defined`);
        }

        const index = state.overrideTemplates.findIndex(x => x.id === id);

        if (index < 0) {
            throw new Error(`Override template (id: ${id}) is not found in state`);
        }

        const newOverrides = [...state.overrideTemplates];
        newOverrides.splice(index, 1);

        return { ...state, overrideTemplates: newOverrides } as KpiState;
    }

    @Action(ApplyInStateOverrideTemplateAction)
    public applyInStateOverrideTemplate(state: KpiState, { copyStatus }: ApplyInStateOverrideTemplateAction) {
        return { ...state, copyStatus: copyStatus } as KpiState;
    }
    //#endregion override templates

    //#region issues
    @Action(UpdateInStateIssuesProblemTimeFlagAction)
    public updateInStateIssuesPtFlagAction(state: KpiState, { issueIds, isProblemTime }: UpdateInStateIssuesProblemTimeFlagAction) {
        if (!state.allIssues) {
            throw new Error('Issues state not defined');
        }

        if (!Array.isArray(issueIds)) {
            throw new Error(`Issue collection is not defined`);
        }

        const newIssues = [...state.allIssues];

        issueIds.forEach(id => {
            const index = state.allIssues.findIndex(y => y.id === id);

            if (index < 0) {
                throw new Error(`Issue (id: ${id}) not found in state`);
            }

            newIssues[index] = { ...newIssues[index], isProblemTime: isProblemTime };
        });

        return { ...state, allIssues: newIssues } as KpiState;
    }
    @Action(UpdateInStateAllIssuesProblemTimeFlagAction)
    public updateInStateAllIssues(state: KpiState, { isProblemTime }: UpdateInStateAllIssuesProblemTimeFlagAction) {
        if (!Array.isArray(state.allIssues)) {
            throw new Error(`Issue collection is not defined`);
        }

        if (isProblemTime == null) {
            throw new Error(`problem time flag is not defined`);
        }

        const newIssues = [...state.allIssues];

        state.allIssues.forEach(issue => {
            const index = state.allIssues.findIndex(y => y.id === issue.id);

            if (index < 0) {
                throw new Error(`Issue (id: ${issue.id}) not found in state`);
            }

            newIssues[index] = { ...newIssues[index], isProblemTime: isProblemTime };
        });

        return { ...state, allIssues: newIssues } as KpiState;
    }
    //#endregion issues

    @Action(LoadInStateIssueReasonCodesAction)
    public loadInStateIssueReasonCodes(state: KpiState, { reasonCodes }: LoadInStateIssueReasonCodesAction) {
        return { ...state, issueReasonCodes: reasonCodes } as KpiState;
    }

    @Action(LoadInStateKpiChartDataAction)
    public loadInStateKpiChartDataAction(state: KpiState, { chartData }: LoadInStateKpiChartDataAction) {
        return { ...state, chartData: chartData } as KpiState;
    }

    @Action(InsertInStateKpiIssueBatchAction)
    public insertInStateKpiIssueBatchAction(state: KpiState, { batch }: InsertInStateKpiIssueBatchAction) {
        let result = state;

        if (batch && batch.length) {
            let newAllIssues = [];
            if (state.allIssues) newAllIssues = newAllIssues.concat(state.allIssues);
            newAllIssues = newAllIssues.concat(batch);
            result = { ...state, issues: batch, allIssues: newAllIssues } as KpiState;
        }

        return result;
    }

    @Action(ClearAllIssuesAction)
    public clearAllIssuesAction(state: KpiState) {
        return { ...state, allIssues: null } as KpiState;
    }

    @Action(StartFetchKpiIssueBatchAction)
    public startFetchKpiIssueBatchAction(state: KpiState) {
        return { ...state, isFetchingIssues: true } as KpiState;
    }

    @Action(FinishFetchKpiIssueBatchAction)
    public finishFetchKpiIssueBatchAction(state: KpiState) {
        return { ...state, isFetchingIssues: false } as KpiState;
    }

    @Action(LoadInStateSectionWithRuleInstancesAction)
    public loadInStateSectionWithRuleInstances(state: KpiState, { section }: LoadInStateSectionWithRuleInstancesAction) {
        return { ...state, sectionWithRuleInstances: section } as KpiState;
    }

    @Action(InsertInStateSectionTimeRangeAction)
    public insertInStateSectionTimeRanges(state: KpiState, { range }: InsertInStateSectionTimeRangeAction) {
        const newRanges = [...state.sectionWithRuleInstances.sectionKpiRanges];
        newRanges.push(range);

        return {
            ...state,
            sectionWithRuleInstances: {
                ...state.sectionWithRuleInstances,
                sectionKpiRanges: newRanges
            }
        } as KpiState;
    }

    @Action(UpdateInStateSectionTimeRangeAction)
    public updateInStateSectionTimeRanges(state: KpiState, { range }: UpdateInStateSectionTimeRangeAction) {
        const newRanges = [...state.sectionWithRuleInstances.sectionKpiRanges];

        const oldRangeIndex = newRanges.findIndex(x => x.id === range.id);

        if (oldRangeIndex === -1) {
            throw new Error(`Range (id: ${range.id}) not found in state`);
        }

        newRanges[oldRangeIndex] = range;

        return {
            ...state,
            sectionWithRuleInstances: {
                ...state.sectionWithRuleInstances,
                sectionKpiRanges: newRanges
            }
        } as KpiState;
    }

    @Action(DeleteInStateSectionTimeRangeAction)
    public deleteInStateSectionTimeRanges(state: KpiState, { range }: DeleteInStateSectionTimeRangeAction) {
        const newRanges = [...state.sectionWithRuleInstances.sectionKpiRanges];

        const oldRangeIndex = newRanges.findIndex(x => x.id === range.id);

        if (oldRangeIndex === -1) {
            throw new Error(`Range (id: ${range.id}) not found in state`);
        }

        newRanges.splice(oldRangeIndex, 1);

        return {
            ...state,
            sectionWithRuleInstances: {
                ...state.sectionWithRuleInstances,
                sectionKpiRanges: newRanges
            }
        } as KpiState;
    }

    @Action(LoadInStateKpiExportSummaryAction)
    public loadInStateExportSummary(state: KpiState, { data }: LoadInStateKpiExportSummaryAction) {
        return { ...state, exportSummary: data } as KpiState;
    }

    @Action(LoadInStateDapKpiExportSummaryAction)
    public loadInStateDapExportSummary(state: KpiState, { data }: LoadInStateDapKpiExportSummaryAction) {
        return { ...state, dapSummary: data } as KpiState;
    }

    @Action(LoadInStateLiveTimeRangesAction)
    public loadInStateLiveTimeRanges(state: KpiState, { ranges }: LoadInStateLiveTimeRangesAction) {
        const sri = {... state.sectionWithRuleInstances, sectionKpiRanges: ranges};
        return { ...state, sectionWithRuleInstances: sri } as KpiState;
    }

    @Action(UpdateInStateKpiExportFlagAction)
    public updateInStateKpiExportFlag(state: KpiState, { sectionId, targetId, hasExport }: UpdateInStateKpiExportFlagAction) {
        let newList = state.sectionExportList ? [...state.sectionExportList] : [];
        if (hasExport) {
            const exist = newList.find(x => x.drqSectionId === sectionId && x.witsmlServerConnectionId === targetId);
            if (!exist) {
                const exportDetails = new KPIExportDetails();
                exportDetails.drqSectionId = sectionId;
                exportDetails.witsmlServerConnectionId = targetId;
                exportDetails.isFinalized = false;
                newList.push(exportDetails);
            } else {
                newList = newList.filter( i => i !== exist );
                const exportDetails = new KPIExportDetails();
                exportDetails.drqSectionId = sectionId;
                exportDetails.witsmlServerConnectionId = targetId;
                exportDetails.isFinalized = false;
                newList.push(exportDetails);
            }
        } else {
            newList = newList.filter(x => x.drqSectionId !== sectionId && x.witsmlServerConnectionId !== targetId);
        }
        return { ...state, sectionExportList: newList } as KpiState;
    }

    @Action(UpdateInStateKpiFinalizeAction)
    public updateInStateKpiFinalizeFlag(state: KpiState, { sectionId, targetId, finalized }: UpdateInStateKpiFinalizeAction) {
        let newList = state.sectionExportList ? [...state.sectionExportList] : [];
        const exist = newList.find(x => x.drqSectionId === sectionId && x.witsmlServerConnectionId === targetId);
        if (exist) {
            newList = newList.filter(i => i !== exist);
            const exportDetails = new KPIExportDetails();
            exportDetails.drqSectionId = sectionId;
            exportDetails.witsmlServerConnectionId = targetId;
            exportDetails.isFinalized = finalized;
            newList.push(exportDetails);
        }
        return { ...state, sectionExportList: newList } as KpiState;
    }

    @Action(UpdateKpiExportStatusAction)
    public updateKpiExportStatus(state: KpiState, { status, reason, progress }: UpdateKpiExportStatusAction) {
        if (state.exportTracking?.canceled) return;
        const exportStatus = new KPIExportStatus();
        exportStatus.status = status;
        exportStatus.reason = reason;
        exportStatus.progress = progress;
        return { ...state, exportStatus: exportStatus } as KpiState;
    }

    @Action(ClearKpiExportStatusAction)
    public clearKpiExportStatusAction(state: KpiState) {
        const exportStatus = new KPIExportStatus();
        exportStatus.status = '';
        exportStatus.reason = '';
        exportStatus.progress = 0;
        return { ...state, exportStatus: exportStatus } as KpiState;
    }


    @Action(MarkKpiExportAsTracked)
    public markKpiExportAsTracked(state: KpiState, { sectionId, targetId }: MarkKpiExportAsTracked) {
        const exportTracking = new KPIExportTracking();
        exportTracking.sectionId = sectionId;
        exportTracking.targetId = targetId;
        exportTracking.canceled = false;
        return { ...state, exportTracking: exportTracking } as KpiState;
    }

    @Action(CancelCurrentKpiExportTracking)
    public cancelCurrentKpiExportTracking(state: KpiState) {
        if (!state.exportTracking) return;
        const exportTracking = {...state.exportTracking};
        exportTracking.canceled = true;
        return { ...state, exportTracking: exportTracking } as KpiState;
    }
}
