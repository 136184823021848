import { SectionMonitorRuleInstance, IndicatorStatus, StateDetails, SectionMonitorInfo } from '@/_models';

export class IssueDetailsDialogVm {
    /** ids are used for navigation */
    public sectionId?: number;
    public targetId?: number;
    public issues: IssueDetailsDialogIssueVm[];
    public issuesProblemTimeDuration?: number;
    public ruleInstanceIds?: number[];
    public targetUrl?: string;
    public sectionInfo?: SectionMonitorInfo;
}

export class IssueDetailsDialogIssueVm {
    public id: number;
    public ruleInstance: SectionMonitorRuleInstance;
    public status: IndicatorStatus;
    public alertId: number;
    /** TBD */
    public reasonCode: number
    public reasonCodeDescription: string;
    /** Created by the Rule */
    public comment?: string;
    public closeReason?: string;
    public taggedCurvesInfo?: string;
    public stateDetails?: StateDetails;
    public ruleParametersInfo?: string;
    public issueDetailsFetched = false;

    public startTime: Date;
    public startTimeTicks: string;
    /** End time of the issue. Opened issues do not have end time */
    public endTime?: Date;
    public startTimeIndex?: Date;
    public endTimeIndex?: Date;
    public startDepthIndex?: number;
    public startDepthIndexUom?: string;
    public endDepthIndex?: number;
    public endDepthIndexUom?: string;

    public problemTimeDuration?: number;
    public duration: string;
    /** Is the issue marked as problem time */
    public problemTime: boolean;
}
